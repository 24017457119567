import PropTypes from "prop-types"
import React, { useState } from "react"

import SectionInner from "../../components/section-inner"
import Section from "../../components/section"
import Pagination from "../../components/pagination"
import ProductCard from "../../components/product-card"
import Grid from "@material-ui/core/Grid"

import "./ProductGrid.scss"
import GridSearch from "../../components/grid-search"
import { getCardMedia } from "../../helper"
import _ceil from "lodash.ceil"

const ProductGrid = ({ title, description, products, activeSearch, activePagination, productsPerPage }) => {



  // productsPerPage = parseInt(productsPerPage)
  // console.log("productsPerPage", productsPerPage)
  // console.log("products.length", products.length)
  // console.log("page Count",  _ceil(products.length / productsPerPage))
  const pageCount = _ceil(products.length / productsPerPage)

  const [state, setState] = useState({
    offset: 0,
    pageCount: pageCount,
    pageProducts: [...products.slice(0, productsPerPage)],
    activePage: 1,
    searchIsActive: false,
  })

  const handlePageChange = (selectedPage) => {
    // console.log("selectedPage", selectedPage)

    let offset = ((selectedPage - 1) * productsPerPage)

    // console.log("offset", offset)
    // console.log("slice from " + offset + " to " + (offset + productsPerPage))
    //
    // console.log("Products on page:", products.slice(offset, offset + productsPerPage))

    setState(prevState => {
      return {
        pageCount: pageCount,
        searchIsActive: false,
        offset: offset,
        activePage: selectedPage,
        pageProducts: products.slice(offset, offset + productsPerPage),
      }
    })
  }

  const handleSearch = (searchValue, products) => {

    const lowercasedSearch = searchValue.toLowerCase()

    setState(prevState => {
      return {
        ...prevState,
        searchIsActive: searchValue !== "",
        pageProducts: searchValue !== "" ? products.filter((product) => {
          const flattenedProduct = {
            title: product.title,
            ...product.product,
          }

          return Object.keys(flattenedProduct).some(key => {
              return flattenedProduct[key] ? flattenedProduct[key].toString().toLowerCase().includes(lowercasedSearch) : false
            },
          )
        }) : products.slice(state.offset, state.offset + productsPerPage),
      }
    })
  }

  // console.log(products)
  //
  // console.log(state.pageProducts)


  return (
    <Section className="ProductGrid Section--vPadding">
      <SectionInner>
        {
          title &&
          <h2 className="Section-title t-h2">{title}</h2>
        }
        {
          description &&
          <div className="Section-text t-body2" dangerouslySetInnerHTML={{ __html: description }}/>
        }
        {
          activeSearch &&
          <GridSearch className="ProductGrid-search" handleSearch={handleSearch} allItems={products}/>
        }
        <div className="ProductGrid-products CardGrid">
          <Grid container justify="flex-start" spacing={5} style={{ position: "relative", marginBottom: "40px" }}>
            {
              state.pageProducts.map((productItem, index) => {
                  const data = {
                    ...productItem.product,
                    title: productItem.title,
                    description: productItem.product.description,
                    cardMedia: getCardMedia(productItem.featuredImage),
                  }
                  return (
                    <Grid key={index} item xs={12} lg={6} xl={4}>
                      <ProductCard {...data}/>
                    </Grid>
                  )
                },
              )
            }
          </Grid>
          {
            activePagination && !state.searchIsActive &&
            <Pagination
              pageCount={state.pageCount}
              pageRangeDisplayed={state.pageCount}
              onPageChange={handlePageChange}
              activePage={state.activePage}
            />
          }
        </div>
      </SectionInner>
    </Section>
  )
}

ProductGrid.propTypes = {
  products: PropTypes.array,
  activeSearch: PropTypes.bool,
  activePagination: PropTypes.bool,
  productsPerPage: PropTypes.number,
}

ProductGrid.defaultProps = {
  activeSearch: true,
  activePagination: true,
  productsPerPage: 9,
}



export default ProductGrid
