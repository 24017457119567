import PropTypes from "prop-types"
import React, { useState } from "react"
import classNames from "classnames"

import Button from "@material-ui/core/Button"
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

import "./pagination.scss"

const Pagination = ({ pageCount, pageRangeDisplayed, onPageChange, showPrevNext, activePage, scrollGridIntoView }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const numberOfPagesShown = pageCount > pageRangeDisplayed ? pageRangeDisplayed : pageCount

  const PagingButton = ({ children, onPageChange, changeTo, activePage }) => {

    const changePage = (to) => {
      setCurrentPage(to)
      onPageChange(to)
    }

    const onButtonClick = (changeTo) => {
      if (changeTo < 1) {
        changePage(1)
      } else if (changeTo > pageCount) {
        changePage(pageCount)
      } else {
        changePage(changeTo)
      }
      if (scrollGridIntoView) scrollGridIntoView()
    }

    return <Button href={null} onClick={() => onButtonClick(changeTo)}
                   className={classNames(
                     "Pagination-button",
                     "u-elevation-1",
                     { active: activePage },
                   )}
                   size={"medium"}
                   style={{ minWidth: "auto", width: "40px", height: "40px" }}>{children}</Button>
  }


  return (
    <div className="Pagination">
      {showPrevNext &&
      <PagingButton onPageChange={onPageChange} changeTo={currentPage - 1}><NavigateBeforeIcon /></PagingButton>
      }
      {
        Array.from({ length: numberOfPagesShown }).map((el, index) => {
          return <PagingButton key={index} activePage={activePage === (index + 1)} onPageChange={onPageChange}
                               changeTo={index + 1}>{index + 1}</PagingButton>
        })
      }
      {showPrevNext &&
      <PagingButton onPageChange={onPageChange} changeTo={currentPage + 1}><NavigateNextIcon /></PagingButton>
      }
    </div>
  )
}

Pagination.propTypes = {
  pageCount: PropTypes.number.isRequired,
  pageRangeDisplayed: PropTypes.number,
  onPageChange: PropTypes.func.isRequired,
  showPrevNext: PropTypes.bool,
}

Pagination.defaultProps = {
  pageRangeDisplayed: 10,
  showPrevNext: true,
}


export default Pagination
