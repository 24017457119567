import React from "react"
import classNames from "classnames"

import "./grid-search.scss"
import TextField from "@material-ui/core/TextField"


const GridSearch = ({ className, searchValue, handleSearch }) => {

  return (
    <TextField
      id="outlined-with-placeholder"
      label="Search"
      type="search"
      placeholder="Search for anything ..."
      margin="dense"
      variant="outlined"
      value={searchValue}
      className={classNames("GridSearch", className)}
      onChange={event => handleSearch(event, event.target.value)}
    />
  )
}

GridSearch.propTypes = {}

export default GridSearch
